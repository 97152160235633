import React from 'react';

function PrivacyPolicy() {
  return (
    <div className='terms-content'>
      <p align="center"><iframe className="iframe-large-container" src="https://docs.google.com/document/d/e/2PACX-1vSf8R6exuBDhNvRBi6maxWwqyNTCrqOYJ6xvkKQgeEYWRpPhgXnXM-OQw4v70Qi723ybFl3Dqx5HySB/pub?embedded=true"></iframe></p>
    </div>
  );
}

export default PrivacyPolicy;
