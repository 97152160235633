import React from 'react';
import './HabitTheory.css';

function HabitTheory(){
    return(
        <div className='terms-content'>
        <p align="center"><iframe className="iframe-large-container" src="https://docs.google.com/document/d/e/2PACX-1vQIs9Xbl7XvBY3zW9-1_Na3XKYgPMPtpuAtVBcz46xhsvQGBbwEFfEv5A1M-7sXJrQyxrWOm07zFQKL/pub?embedded=true"></iframe></p>
        </div>
    )
}

export default HabitTheory