import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './Contact.css';


const Contact = () => {
  return (
    <div className="page-content">
        <h2>Contact Us</h2>
        <p>Please feel free to contact us for any inquiries or questions.</p>
        <p>Email: <a href="mailto:info@thedailygainapps.com" className="email-link">info@thedailygainapps.com</a></p>
        <br></br>
        <a href="https://www.facebook.com/people/The-Daily-Gain-Inc/100084624979535/" className="social-button facebook">
            <FontAwesomeIcon icon={faFacebook} className="social-icon" />
            Like us on Facebook
        </a>
        <a href="https://www.linkedin.com/company/80285275/" className="social-button linkedin">
            <FontAwesomeIcon icon={faLinkedin} className="social-icon" />
            Reach out on LinkedIn
        </a>
        <a href="https://www.instagram.com/thedailygainapps/" className="social-button instagram">
            <FontAwesomeIcon icon={faInstagram} className="social-icon" />
            Follow us on Instagram
        </a>
    </div>
  );
};

export default Contact;
