import React, { useState, useEffect } from 'react';
import './PresetGoals.css';

const PresetGoals = () => {
  const [selectedGoal, setSelectedGoal] = useState(null);
  
  const goals = [
    {
      title: 'Journal',
      image: '/journal.png',
      link: 'https://docs.google.com/document/d/e/2PACX-1vR8I2ZQt6RlGNhMkUzBY29jwzZ3y8U9Ybu4jjWuqJw45wJX63DKHQGAQNoQ7amd0Y7YnRVi-4Rvy1ff/pub?embedded=true'
    },
    {
      title: 'Meditate',
      image: '/meditate.png',
      link: 'https://docs.google.com/document/d/e/2PACX-1vQJvLNhKp20xbeqE2ODT7a8Fa_t334g2mcO_dx7WDAxV6daw_ye_Th3-ujccXRrLKEYNzfuPLObW3G9/pub?embedded=true'
    },
    {
      title: 'Exercise',
      image: '/exercise.png',
      link: 'https://docs.google.com/document/d/e/2PACX-1vSYfhAuP_8W4K5avHzYwPBuTU_nupRaax_cR-SH2fFhfPt7An1vMbzzs-flVCoGX2oJPE4UzfIDiz-H/pub?embedded=true'
    },
    {
      title: 'Cook',
      image: '/cook.png',
      link: 'https://docs.google.com/document/d/e/2PACX-1vR3ttJZedLB569B4Qw4_a1Nw0UiGQ25B7EZEu1Wp5c7rBeUtpzUOQRpBfqFzyemJKXB4pJAZk9_sTAV/pub?embedded=true'
    },
    {
      title: 'Focus',
      image: '/focus.png',
      link: 'https://docs.google.com/document/d/e/2PACX-1vRG9aieqZ7hIwInIhJ5k64E9m5GGg3zIZF375XF-pK7wE6yFQpWbYBWlXyf_1fiGHgXB5uHXHLTqqB2/pub?embedded=true'
    },
    {
      title: 'Read',
      image: '/read.png',
      link: 'https://docs.google.com/document/d/e/2PACX-1vT7Oz8OmjPYNG7BPcGTPqwpF4xwbVeDbIi57gwXGKDnEAjloxwy_yeXX79Gx_dnalui255vQDQOHcOE/pub?embedded=true'
    }
  ];


  const handleGoalClick = (goalLink) => {
    setSelectedGoal(goalLink);
    window.history.pushState(null, '', '/preset-goals');
  };

  useEffect(() => {
    const handlePopstate = () => {
      if (selectedGoal !== null) {
        setSelectedGoal(null);
      }
    };

    window.onpopstate = handlePopstate;

    return () => {
      window.onpopstate = null;
    };
  }, [selectedGoal]);

  return (
    <div className="page-content">
      {selectedGoal ? (
        <div>
          <p align="center">
            <iframe title="Selected Goal" src={selectedGoal} className="iframe-large-container" />
          </p>
        </div>
      ) : (
        <div>
          <h2>Preset Goals</h2>
          <div className="goal-grid">
            <div className="goal-grid-container">
              {goals.map((goal, index) => (
                <div
                  key={index}
                  className="goal-card"
                  onClick={() => handleGoalClick(goal.link)}
                >
                  <img src={goal.image} alt={goal.title} />
                  <h3>{goal.title}</h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PresetGoals;
