import React, { useRef, useEffect } from 'react';
import './Home.css';

const Home = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    video.play();
    video.addEventListener('ended', handleVideoEnded, false);

    return () => {
      video.removeEventListener('ended', handleVideoEnded, false);
    };
  }, []);

  const handleVideoEnded = () => {
    const video = videoRef.current;
    video.currentTime = 0;
    video.play();
  };

  return (
    <div className="page-content">
      <div className="background-video">
        <video ref={videoRef} autoPlay loop muted playsInline>
          <source src="/loadingHome.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="content-overlay">
        <div className="logo-container">
          <img className="logo" src="/logo.png" alt="Website Logo" />
        </div>
        <p>The Daily Gain Inc. is a leading company with a decade of experience that specializes in creating innovative mobile applications for businesses.</p>
        {/* <p>With our team of experienced developers and designers, we deliver cutting-edge mobile applications that enhance business processes, improve customer engagement, and drive growth.</p> */}
        {/* {<p>In addition to our client projects, we also invest in our own product development to push the boundaries of mobile technology.</p>} */}
        {/* <p>At The Daily Gain Inc., we strive for excellence in every project we undertake.</p> */}
        {/* <p>Our commitment to delivering exceptional results has earned us a solid reputation in the industry.</p> */}
        <p>Get in touch with us today to discuss how we can help your business succeed through the power of mobile applications.</p>
      </div>
    </div>
  );
};

export default Home;
