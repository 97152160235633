import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';

import { FaMailBulk, FaHome, FaProductHunt, FaUserTie, FaShieldAlt } from 'react-icons/fa';

import Home from './pages/Home';
import Contact from './pages/Contact';
import Products from './pages/Products';
import Consulting from './pages/Consulting';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PresetGoals from './pages/PresetGoals';
import HabitTheory from './pages/HabitTheory';

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    if (window.innerWidth <= 768) {
      setMenuOpen(!menuOpen);
    }
  };

  const closeMobileMenu = () => {
    if (window.innerWidth <= 768) {
      setMenuOpen(false);
    }
  };

  const handleWindowResize = () => {
    if (window.innerWidth > 768) {
      setMenuOpen(true);
    } else {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize(); // Check initial device width

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <nav>
            <div className="hamburger-container" onClick={handleMenuToggle}>
              <div className={`hamburger-menu ${menuOpen ? 'open' : ''}`}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            {menuOpen && (
              <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
                <li>
                  <Link to="/" onClick={closeMobileMenu}>
                    <FaHome className="nav-icon" />
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/products" onClick={closeMobileMenu}>
                    <FaProductHunt className="nav-icon" />
                    Products
                  </Link>
                </li>
                <li>
                  <Link to="/consulting" onClick={closeMobileMenu}>
                    <FaUserTie className="nav-icon" />
                    Consulting
                  </Link>
                </li>
                <li>
                  <Link to="/privacy-policy" onClick={closeMobileMenu}>
                    <FaShieldAlt className="nav-icon" />
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/contact-us" onClick={closeMobileMenu}>
                    <FaMailBulk className="nav-icon" />
                    Contact Us
                  </Link>
                </li>
              </ul>
            )}
          </nav>
        </header>
        <main>
          <Switch>
            <Route path="/products/daily-gain-app/preset-goals">
              <PresetGoals />
            </Route>
            <Route path="/products/daily-gain-app/habit-theory">
              <HabitTheory />
            </Route>
            <Route path="/products">
              <Products />
            </Route>
            <Route path="/consulting">
              <Consulting />
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/contact-us">
              <Contact />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </main>
        <footer>
          <p>&copy; {new Date().getFullYear()} The Daily Gain Inc. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
