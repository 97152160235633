import React from 'react';
import { Link } from 'react-router-dom';
import './Products.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';

function Products() {
  return (
    <div className='page-content'>
      <ul className="product-list">
        <h3 className="product-title">The Daily Gain App</h3>
        <h5>Advanced habit tracker</h5>
        <li className="product-item">
          <Link to="/products/daily-gain-app/preset-goals">
            View Preset Goals
          </Link>
        </li>
        <li className="product-item">
          <Link to="/products/daily-gain-app/habit-theory">
            Understand the Generic Habit Theory
          </Link>
        </li>
      </ul>

      <div className="app-container">
        <img src="/thedailygain.png" className="app-image" alt="The Daily Gain App" />
        <div className="app-buttons">
          <a href="https://apps.apple.com/ca/app/the-daily-gain/id1549690217" className="download-button app-store">
            <FontAwesomeIcon icon={faApple} className="store-icon" />
            Download on the App Store
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.jrd.thedailygain" className="download-button google-play">
            <FontAwesomeIcon icon={faGooglePlay} className="store-icon" />
            Get it on Google Play
          </a>
        </div>
      </div>

      <h4 className="product-title">Frugal Piggy</h4>
      <h5>Simple expense tracker</h5>
      <div className="app-container">
        <img src="/piggy.png" className="app-image" alt="Frugal Piggy App" />
        <div className="app-buttons">
          <a href="https://apps.apple.com/ca/app/frugal-piggy/id1627827838" className="download-button app-store">
            <FontAwesomeIcon icon={faApple} className="store-icon" />
            Download on the App Store
          </a>
        </div>
      </div>
    </div>
  );
}

export default Products;
