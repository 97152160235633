import React from 'react';

import './Consulting.css';

function Consulting() {
  return (
    <div className='page-content'>
      <h1 className="section-title">Consulting</h1>
      <p className="description">
        We offer consulting services to help businesses achieve their goals. Our experienced team provides comprehensive solutions by designing the system architecture or developing mobile apps tailored to your specific needs. We also offer ongoing monitoring and stability support to ensure your products perform optimally.
        Whehter you want to use native or hybrid technologies, we can help you build a mobile app that will delight your users.
      </p>
      <p className="description">
        As part of our consulting process, we utilize A/B testing to experiment with new features and optimize user engagement. Our mission is to measure, adapt, and maximize traction to drive your business forward.
      </p>
      <section className="clients-section">
        <h2 className="clients-title">Clients</h2>
        <ul className="clients-list">
            <a href="https://www.shop4me.com" target="_blank" rel="noopener noreferrer">
              <img className="client-image" src='/shop4me.png' alt="Shop4me Inc." />
            </a>
            <p className="subtitle"> A B2B food and goods delivery business active in Jordan</p>
            <a href="https://www.funbud.com" target="_blank" rel="noopener noreferrer">
              <img className="client-image"  src='/funbud.png' alt="Funbud Inc." />
            </a>
            <p className="subtitle"> A B2C mobile app that helps parent find babysitters for their kids active in Canada </p>
            <a href="https://www.bettingonthewedding.com" target="_blank" rel="noopener noreferrer">
              <img className="client-image" src='/botw.png' alt="Excise LLC." />
            </a>
            <p className="subtitle"> A B2C mobile app that helps wedding organizer create fun bets on their wedding night in USA </p>
            <a href="https://www.ezphyzique.com" target="_blank" rel="noopener noreferrer">
              <img className="client-image" src='/ezphyzique.png' alt="Ezphyzique LLC." />
            </a>
            <p className="subtitle"> A B2C meal prep and gym coaching company based in Canda</p>
        </ul>
      </section>
    </div>
  );
}

export default Consulting;
